import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Select from '@emcasa/ui-dom/components/Select';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <p>{`Select component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Select\n  defaultValue=\"_placeholder\"\n  onChange={({ target: { value } }) => {\n    console.log(\'selected:\', value)\n  }}\n>\n  <option value=\"_placeholder\" disabled>\n    Placeholder option\n  </option>\n  <option value=\"first\">Fist option</option>\n  <option value=\"second\">Second option</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Select
    }} mdxType="Playground">
  <Select defaultValue="_placeholder" onChange={({
        target: {
          value
        }
      }) => {
        console.log('selected:', value);
      }} mdxType="Select">
    <option value="_placeholder" disabled>Placeholder option</option>
    <option value="first">Fist option</option>
    <option value="second">Second option</option>
  </Select>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      